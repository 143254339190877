import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/github/workspace/packages/villa-clementine/src/gatsby/gatsby-tpl/mdx-layout-default.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Maison individuelle de 144 m² sur un terrain de 992 m²`}</h2>
    <h3>{`Piscine et pool house(9 m²)`}</h3>
    <h3>{`Double garage (52 m²)`}</h3>
    <h3>{`Atelier (23 m²)`}</h3>
    <h3>{`Au rez de jardin :`}</h3>
    <ul>
      <li parentName="ul">{`Entrée ouverte sur la salle de séjour (21 m²)`}</li>
      <li parentName="ul">{`Terrasse (20 m²)`}</li>
      <li parentName="ul">{`Salon (24m²)`}</li>
      <li parentName="ul">{`Cuisine indépendante (16m²)`}</li>
      <li parentName="ul">{`Chambre (10m²) avec salle d’eau (3,5 m²)`}</li>
      <li parentName="ul">{`WC`}</li>
    </ul>
    <h3>{`Studio indépendant traversant (21 m²)`}</h3>
    <ul>
      <li parentName="ul">{`Cuisine équipée`}</li>
      <li parentName="ul">{`Salle d’eau`}</li>
      <li parentName="ul">{`Rangement`}</li>
      <li parentName="ul">{`Terrasse`}</li>
    </ul>
    <h3>{`A l’étage`}</h3>
    <ul>
      <li parentName="ul">{`Chambre parentale (17,4 m²)`}</li>
      <li parentName="ul">{`Salle de bain et WC (6,6m²)`}</li>
      <li parentName="ul">{`Terrasse (19 m²)`}</li>
    </ul>
    <h3>{`Au niveau supérieur`}</h3>
    <ul>
      <li parentName="ul">{`Chambre (12,2 m²)`}</li>
      <li parentName="ul">{`Salle d’eau (2,4 m²)`}</li>
      <li parentName="ul">{`WC`}</li>
      <li parentName="ul">{`Balcon (5,8 m²)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      